import { User } from 'models';
import request from 'requesters/index';

const authService = {
  signUpWithPassword: async (values: any) => {
    return request.post<{ registerUserOutput: { token: string } & any }>('/auth/register', values);
  },
  signInWithPassword: async (values: { email: string; password: string }) => {
    return request.post<{ token: string; user: User }>('/auth/login', values);
  },
  signOut: async () => {
    return request.post('/logout');
  },
  userProfile: async () => {
    return request.get<User>('/auth/user-profile');
  },
  loginWithGoogle: async (data: { token: string }) => {
    return request.post<any>('/auth/login-by-email', data);
  },
};

export default authService;
