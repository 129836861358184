import { Category } from 'models';
import request from 'requesters/index';
import { PaginationData } from 'types';
const categoryService = {
    getCategories: async (params?: any) => {
        return await request.get<PaginationData<Category>>('/categories',{ params });
    },
    updateCategory: async (categoryId: string | number, data: any) => {
        return await request.put<Category>(`/categories/${categoryId}`, data);
    },
    createCategory: async (data: any) => {
        return await request.post<Category>('/categories', data);
    },
    deleteCategory: async (categoryId: string | number) => {
        return await request.delete(`/categories/${categoryId}`);
    }
};

export default categoryService;