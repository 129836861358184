import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import type { TimeRangePickerProps } from 'antd';

export default function DatePickerRange(props: any) {
  const { ...otherProps } = props;

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: '1 Ngày trước', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
    { label: '7 Ngày trước', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: '15 Ngày trước', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: '30 Ngày trước', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: '90 Ngày trước', value: [dayjs().add(-90, 'd'), dayjs()] },
  ];

  return (
    <DatePicker.RangePicker
      placeholder={['Từ ngày', 'Đến ngày']}
      presets={rangePresets}
      {...otherProps}
    />
  );
}
