import { lazy, Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AppRouteType } from '../types';
import useAuthContext from 'store/auth-context';
import { Layout } from 'components';
const SignIn = lazy(() => import('../pages/SignIn'));
const SignUp = lazy(() => import('../pages/SignUp'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const CreateWebsiteBuilder = lazy(() => import('../pages/CreateWebsiteBuilder'));
const WebsiteBuilderList = lazy(() => import('../pages/WebsiteBuilderList'));
const Categories = lazy(() => import('../pages/Categories'));
const InventoryPage = lazy(() => import('../pages/Inventories'));
const NotFound = lazy(() => import('../pages/404'));

export const routes: AppRouteType[] = [
  {
    name: 'SignIn',
    auth: false,
    path: '/sign-in',
    isPublic: true,
    component: SignIn,
  },
  {
    name: 'SignUp',
    auth: false,
    path: '/sign-up',
    isPublic: true,
    component: SignUp,
  },
  {
    name: 'Dashboard',
    auth: true,
    path: '/',
    isPublic: true,
    component: Dashboard,
  },
  {
    name: 'WebsiteBuilderList',
    auth: true,
    path: '/web-builders',
    isPublic: true,
    component: WebsiteBuilderList,
  },
  {
    name: 'CreateWebsiteBuilder',
    auth: true,
    path: '/web-builders/create',
    isPublic: true,
    component: CreateWebsiteBuilder,
  },
  {
    name: 'Categories',
    auth: true,
    path: '/category-list',
    isPublic: true,
    component: Categories,
  },
  {
    name: 'Inventories',
    auth: true,
    path: '/inventories',
    isPublic: true,
    component: InventoryPage,
  },
  {
    name: 'NotFound',
    auth: false,
    path: '/404',
    isPublic: true,
    component: NotFound,
  },
];
const waitFor = (Tag: React.LazyExoticComponent<() => JSX.Element | null>) => (
  <Suspense fallback={null}>
    <Tag />
  </Suspense>
);
const landingRoute = routes.find((route) => route.name === 'Dashboard') as AppRouteType;
export const landingPage = landingRoute;
export default function AppRoutes() {
  const { profile } = useAuthContext();
  const location = useLocation();

  const publicRoutes = routes
    .filter((route) => !route.auth || route.isPublic)
    .map((route) => <Route key={route.path} path={route.path} element={waitFor(route.component)} />);
  if (!profile) {
    return (
      <Routes key={location.pathname} location={location}>
        {publicRoutes}
      </Routes>
      // </AnimatePresence>
    );
  }

  // authenticated routes
  const authenticatedRoutes = routes
    .filter((route) => route.auth || route.isPublic)
    .map((route) => <Route key={route.path} path={route.path} element={waitFor(route.component)} />);

  return (
    <Layout>
      <Routes key={location.pathname} location={location}>
        {authenticatedRoutes}
      </Routes>
    </Layout>
  );
}
