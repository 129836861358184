import Cookies from 'js-cookie';
import moment from 'moment';

export const setToken = (accessToken: string) => {
  Cookies.set('holyAgencyAccessToken', accessToken);
  Cookies.set('holyAgencyLoginDate', moment().format('YYYY-MM-DD HH:mm:ss'));
};

export const getAccessToken = (): string | undefined => {
  const accessToken = Cookies.get('holyAgencyAccessToken');
  if (accessToken && accessToken !== 'undefined') {
    return accessToken;
  }
  return '';
};
export const getRefreshToken = (): string | undefined => {
  return Cookies.get('holyAgencyRefreshToken');
};
export const removeToken = () => {
  Cookies.remove('holyAgencyAccessToken');
  Cookies.remove('holyAgencyLoginDate');
};
