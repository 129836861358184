import { useState } from 'react';

export default function useMergeState<Payload>(
  initialState: Payload
): [state: Payload, setMergedState: Function] {
  const [state, setState] = useState<Payload>(initialState);
  const setMergedState = (newState: Payload, isReset = false) =>
    setState((prevState) =>
      isReset ? newState : { ...prevState, ...newState }
    );
  return [state, setMergedState];
}
