import { WebsiteUser } from 'models';
import Website from 'models/Website';
import request from 'requesters/index';
import { PaginationData } from 'types';
const inventoryService = {
  getInventories: async (params?: any) => {
    return await request.get<PaginationData<Website>>('/websites', { params });
  },
  getFavoriteWebsites: async (params?: any) => {
    return await request.get<PaginationData<Website>>('/websites/favorite', { params });
  },
  maskFavoriteWebsite: async (id: string, params?: any) => {
    return await request.post<WebsiteUser>(`/websites/${id}/mask-as-favorite`, { params });
  },
  unmaskFavoriteWebsite: async (id: string, params?: any) => {
    return await request.post<WebsiteUser>(`/websites/${id}/unmask-as-favorite`, { params });
  },
};

export default inventoryService;
