import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

const loadingSchemes = [true, false] as const;

export type LoadingSchemes = (typeof loadingSchemes)[number];

interface LoadingSchemeState {
  value: LoadingSchemes;
}

const initialState: LoadingSchemeState = {
  value: true,
};

export const loadingSchemeSlice = createSlice({
  name: "loadingScheme",
  initialState,
  reducers: {
    setLoadingScheme: (state, action: PayloadAction<LoadingSchemes>) => {
      state.value = action.payload;
    },
  },
});

export const { setLoadingScheme } = loadingSchemeSlice.actions;

export const selectLoadingScheme = (state: RootState) => {
  return state.loadingScheme.value;
};

export default loadingSchemeSlice.reducer;
