import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Category } from "models";
import {categoryService} from 'services';
import { RootState } from "store";
import { Loading, SearchData } from "types";

interface CategoryState {
    categories: Category[];
    categoriesTotal: number;
    categoriesLoading: Loading;
    category: Category | null;
    categoryLoading: Loading;
}

const name = 'category';
const initialState: CategoryState = {
    categories: [],
    categoriesTotal: 0,
    categoriesLoading: 'idle',
    category: null,
    categoryLoading: 'idle',
};

export const getCategories = createAsyncThunk(
    `${name}/list-categories`,
    async (query: SearchData, { rejectWithValue }) => {
        try {
            return await categoryService.getCategories({
                ...query,
            });
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const categorySlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCategories.pending, (state) => {
                state.categoriesLoading = 'pending';
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.categoriesLoading = 'idle';
                state.categories = action.payload.rows;
                state.categoriesTotal = action.payload.count;
            })
            .addCase(getCategories.rejected, (state) => {
                state.categoriesLoading = 'idle';
            });
    },
});

export const selectCategories = (state: RootState) => {
    return state.category.categories;
};
export const selectCategoriesLoading = (state: RootState) => {
    return state.category.categoriesLoading;
};

export const selectCategoriesTotal = (state: RootState) => {
    return state.category.categoriesTotal;
};

export default categorySlice.reducer;