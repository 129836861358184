import { ClockCircleOutlined } from '@ant-design/icons';
import { Card, Statistic, Tag } from 'antd';
type KpiStatisticProps = {
  status: string;
  value: number;
  title: string;
  isOverdue?: boolean;
  isActive?: boolean;
  onClick?: () => void;
};

export default function KpiStatistic(props: KpiStatisticProps) {
  const { status, value, title, isOverdue, isActive, onClick } = props;
  return (
    <Card
      bordered={false}
      className={`statistic-kpi ma-0 ${status} ${isActive ? 'active' : 'inactive'}`}
      onClick={onClick}
      hoverable
    >
      <div style={{ position: 'absolute', top: 0, right: 0, padding: '0px 5px' }}>
        {isOverdue ? <ClockCircleOutlined style={{ color: '#FF4D4F' }} /> : ''}
      </div>
      <Statistic title={<Tag>{title}</Tag>} value={value} />
    </Card>
  );
}
