import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { resetAll } from 'store';
import { Loading, QueryParams } from 'types';
import { inventoryService } from 'services';
import Website from 'models/Website';
interface WebsiteState {
    inventories: Website[];
    inventoriesLoading: Loading;
    inventoriesCount: number;
}

const name = 'website';
const initialState: WebsiteState = {
    inventories: [],
    inventoriesLoading: 'idle',
    inventoriesCount: 0,
};

const setState = (state: any, field: string, value: any) => {
  state[field] = value;
};

export const fetchInventories = createAsyncThunk(
  `${name}/list-of-inventories`,
  async (query: QueryParams, { rejectWithValue, getState }) => {
    try {
      const response = await inventoryService.getInventories(query);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const websiteSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // the resetAll extra reducer is mandatory
    builder.addCase(resetAll, () => initialState);
    builder
      .addCase(fetchInventories.pending, (state, action) => setState(state, 'inventoriesLoading', 'pending'))
      .addCase(fetchInventories.fulfilled, (state, action) => {
        setState(state, 'inventoriesLoading', 'idle');
        setState(state, 'inventories', action.payload.rows);
        setState(state, 'inventoriesCount', action.payload.count);
      })
      .addCase(fetchInventories.rejected, (state, action) => setState(state, 'inventoriesLoading', 'idle'));
    
  },
});
export default websiteSlice.reducer;
