import React, { useState } from 'react';
import BrandLogo from '../../assets/images/logo-dark.png';
import BrandlightLogo from '../../assets/images/logo-light.png';
import BrandSmLogo from '../../assets/images/logo-sm.png';
import { Menu, MenuProps } from 'antd';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { themecolor } from '../../config/config';
import { StyleSimpleBar, StyledCollapsedButton, StyleBrandLogo, StyleSider } from './SidebarStyle';
import { useLocation, useNavigate } from 'react-router-dom';
import { menus } from 'constants/menu';

const SidebarLayout = ({ theme }: { theme: any }) => {
  const items = menus;
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    const antHeaderMain = document.getElementById('antHeaderMain');
    if (antHeaderMain) {
      antHeaderMain.style.left = !collapsed ? '100px' : '260px';
    }
    const antLayoutContent = document.getElementById('antLayoutContent');
    if (antLayoutContent) {
      antLayoutContent.style.marginLeft = !collapsed ? '100px' : '260px';
    }
    const antFooterLayout = document.getElementById('antFooterLayout');
    if (antFooterLayout) {
      antFooterLayout.style.marginLeft = !collapsed ? '100px' : '260px';
    }
  };

  const location = useLocation();
  const [activatedItem, setActivatedItem] = useState(() => {
    const currentPath = location.pathname.replace('/', '');
    return currentPath || 'dashboard';
  });

  const toggleActivation = (key: any) => {
    setActivatedItem((prevActivatedItem) => (prevActivatedItem === key ? null : key));
  };

  const onClick: MenuProps['onClick'] = (e) => {
    toggleActivation(e.key);
    switch (e.key) {
      case '/':
        navigate('/');
        break;
      case '#':
        break;
      default:
        if (!e.key.startsWith('#')) navigate(`${e.key}`);
        break;
    }
  };

  return (
    <React.Fragment>
      <StyleSider
        id='sidebar-layout'
        width={themecolor.components.Menu.verticalSidebarWidth}
        collapsed={collapsed}
        collapsedWidth='100'
        breakpoint='lg'
      >
        <StyleBrandLogo className='demo-logo ant-mx-auto'>
          <img
            alt='Brand logo'
            src={theme === 'dark' ? BrandlightLogo : BrandLogo}
            height={30}
            style={{ lineHeight: '24px' }}
            className='brand-dark-logo ant-mx-auto'
          />
          <img
            alt='Brand sm logo'
            src={BrandSmLogo}
            height={24}
            style={{ lineHeight: '24px' }}
            className='brand-sm-logo ant-mx-auto'
          />
          <StyledCollapsedButton type='link' onClick={toggleCollapsed}>
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </StyledCollapsedButton>
        </StyleBrandLogo>
        <div>
          <StyleSimpleBar>
            <Menu selectedKeys={[activatedItem]} mode='inline' theme='light' items={items} onClick={onClick}></Menu>
          </StyleSimpleBar>
        </div>
      </StyleSider>
    </React.Fragment>
  );
};

export default SidebarLayout;
