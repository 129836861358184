import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the structure of the context data
interface ThemeContextType {
  theme: string;
  toggleTheme: () => void;
}

// Provide a default value matching the structure
const ThemeContext = createContext<ThemeContextType>({
  theme: 'light', // default theme
  toggleTheme: () => {}, // default function
});

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};
